<template>
  <div>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <b-form-group
          label="Judul"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="title"
            placeholder="Masukkan Judul Berita"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-form-group
          label="link"
          label-for="link"
        >
          <b-form-input
            id="link"
            v-model="link"
            placeholder="Masukkan Link View Berita"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-form-group
          label="Kategori"
          label-for="kategori"
        >
          <v-select
            v-model="selectedKategori"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="kategori"
            placeholder="pilih salah satu"
          />
        </b-form-group>
        <b-form-group
          label="Deskripsi"
          label-for="deskrip"
        >
          <b-form-textarea
            id="textArea"
            v-model="desc"
            placeholder="Masukkan deskripsi"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-form-group
          label="Poster"
          label-for="poster"
        >
          <vue-dropzone
            id="dropzone"
            ref="dropPoster"
            :options="dropzoneOptions"
            @vdropzone-complete="uploadPoster"
            @vdropzone-error="dokumenError"
            @vdropzone-removed-file="poster = undefined"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-button
          variant="success"
          @click="getDataKabar()"
        >
          Tambah Kabar Mitra
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import 'vue-select/dist/vue-select.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vue2Dropzone from 'vue2-dropzone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vSelect from 'vue-select'
import firebase from 'firebase/app'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'
import { db, storage } from '@/firebase'

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BButton,
    ToastificationContent,
    BButtonGroup,
  },
  data() {
    return {
      title: '',
      link: '',
      kategori: [
        { value: 'all', label: 'All' },
        { value: 'kabarmitra', label: 'Kabar Mitra' },
      ],
      selectedKategori: '',
      desc: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFilesize: 2,
        dictDefaultMessage: 'Taruh Foto Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
      },
      poster: undefined,
    }
  },
  methods: {
    getDataKabar() {
      db.collection('appConfig').doc('mobile_mitra').collection('info_mitra')
        .add({
          title: this.title,
          link: this.link,
          kategori: this.selectedKategori.value,
          tanggal: firebase.firestore.FieldValue.serverTimestamp(),
          desc: this.desc,
          url_thumbnail: this.poster,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Poster Berhasil Disubmit',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'kabar' })
        })
    },
    async uploadPoster(file) {
      const filetype = file.name.split('.').slice(-1)
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/kabar/${this.title}/poster.${filetype}`)
      await ref.put(file, metadata)

      this.poster = await ref.getDownloadURL()
    },
    dokumenError(file, message, xhr) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
